.my_masonry_grid {
  display: flex;
}

.my_masonry_grid_column {
  background-clip: padding-box;
}

.my_masonry_grid__column > div {
  padding: 10px; /* space between items */
}

.figure {
  position:relative;
  margin: 0;
}

.figure .figure__caption {
  opacity: 0;

  bottom: 20px;
  left: 20px;
  right: 20px;

  height:300px;
}

.figure:hover .figure__caption {
  /* linear gradient from bottom to top */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.05) 5%,
    rgba(0, 0, 0, 0.15) 15%,
    rgba(0, 0, 0, 0.3) 30%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  
  opacity: 1;
  overflow: hidden;
}

.figure__caption p {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
}